<!-- Finder expansible de Tipos de Modalidades -->

<template>
  <div class="productosMX" v-if="loaded">
    <v-sheet class="contenedor" :elevation="0">

      <!-- Botones Mto -->
      <div class="pb-2 d-flex justify-left">
        <baseBtraMto class="conflex" style="width:230px;"
          :modulo="btMtoCfg"
          :estado="estado"
          @onEvent="execAccion">
        </baseBtraMto>
      </div>


      <!-- Controles del Mto -->
      <div class="columna">
        <div class="conflex" style="align-items: center;">
          <uiText
            style="width:52%"
            v-model=" ct.name[2]"
            :label=" ct.name[1]"
            :disabled="noEdit">
          </uiText>

          <v-select
            style="width:32%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-left: 5px;"
            v-bind="$select"
            v-model="ct.clave[2]"
            :items="$store.state.datos_iniciales.productoclaves"
            item-value="id"
            item-text="clave"
            :disabled="noEdit">
          </v-select>

          <v-checkbox
            class="ml-2"
            style="width:14%"
            v-bind="$checkbox"
            v-model="ct.nosubida[2]"
            :label="ct.nosubida[1]">
          </v-checkbox>
        </div>
      </div>
    </v-sheet>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseBtraMto from "@/base/baseBtraMto";

  export default {
    mixins: [mixM],
    components: { baseBtraMto },
    props: {},

    data() {
      return {
        stIni: {
          api: "productosMX",
          name:"productosMX",
          titulo:"",
          recordAccess:"local",
          mView:'productosMX',
          pView:[]
        },
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)");
      },


      // inicializo variables Btra Mto
      iniBtra() {
        this.btMtoCfg= JSON.parse(JSON.stringify(this.$cfe.btra.MX));
      },

    }
};
</script>
